import { ref, watch, computed } from '@vue/composition-api';
import store from '@/store';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default function useCouponsList() {
    // Use toast
    const toast = useToast();

    const refCouponListTable = ref(null);

    // Table Handlers
    let tableColumns = [
        { key: 'code', sortable: true, label: 'Código' },
        { key: 'coupon_type_name', sortable: true, label: 'Tipo de cupón' },
        { key: 'discount_number', sortable: true, label: 'Importe del cupón' },
        { key: 'expiration_date', sortable: true, label: 'Fecha de caducidad' },
        { key: 'bookings_count', sortable: true, label: 'Cantidad de usos' },
        { key: 'actions', label: 'Opciones' }
    ];

    const perPage = ref(10);
    const totalCoupons = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref('');
    const sortBy = ref('id');
    const isSortDirDesc = ref(false);

    const rangePickerFilter = ref(null);
    const typesFilter = ref(null);

    const dataMeta = computed(() => {
        const localItemsCount = refCouponListTable.value ? refCouponListTable.value.localItems.length : 0;
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalCoupons.value,
        }
    });

    const refetchData = () => {
        refCouponListTable.value.refresh();
    };

    watch([currentPage, perPage, searchQuery, rangePickerFilter, typesFilter], () => {
        refetchData();
    });

    const fetchCoupons = (ctx, callback) => {
        store
            .dispatch('app-coupon/fetchCoupons', {
                q: searchQuery.value,
                perPage: perPage.value,
                page: currentPage.value,
                sortBy: sortBy.value,
                sortDesc: isSortDirDesc.value,

                range: rangePickerFilter.value,
                types: typesFilter.value
            })
            .then(response => {
                const { coupons, total } = response.data;
                callback(coupons);
                totalCoupons.value = total;
            })
            .catch(() => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error al obtener la lista de cupones de descuento',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger'
                    }
                });
            })
    };

    return {
        fetchCoupons,
        tableColumns,
        perPage,
        currentPage,
        totalCoupons,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refCouponListTable,

        refetchData,

        // Extra Filters
        rangePickerFilter,
        typesFilter
    }
}