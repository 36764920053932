<template>

    <div>

		<!-- Filters -->
		<coupons-list-filters
            :range-picker-filter.sync="rangePickerFilter"
            :types-filter.sync="typesFilter"
            :types-options="typesOptions"
        />

        <!-- Table Container Card -->
        <b-card
            no-body
            class="mb-0">

            <div class="m-2">

                <!-- Table Top -->
                <b-row>

                    <!-- Per Page -->
                    <b-col
                        cols="12"
                        md="6"
                        class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Mostrar</label>
                        <v-select
                            v-model="perPage"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50"
                        />
                        <label>registros</label>
                    </b-col>

                    <!-- Search -->
                    <b-col
                        cols="12"
                        md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input
                                v-model="searchQuery"
                                class="d-inline-block mr-1"
                                placeholder="Buscar ..."
                            />
                            <b-button
                                variant="primary"
                                :to="{ name: 'coupons-create' }">
                                <span class="text-nowrap">Agregar Cupón</span>
                            </b-button>
                        </div>
                    </b-col>
                </b-row>

            </div>

            <b-table
                ref="refCouponListTable"
                class="position-relative"
                :items="fetchCoupons"
                responsive
                :fields="tableColumns"
                primary-key="id"
                :sort-by.sync="sortBy"
                show-empty
                empty-text="No se encontraron registros coincidentes"
                :sort-desc.sync="isSortDirDesc">

                <!-- Column: Code -->
                <template #cell(code)="data">
					<b-link
						:to="{ name: 'coupons-edit', params: { id: data.item.id } }"
                        class="font-weight-bold d-block text-nowrap">
                        {{ data.item.code}}
                    </b-link>
				</template>

                <!-- Column: Discount Number -->
                <template #cell(discount_number)="data">
                    <span v-if="data.item.coupon_type.name == 'MONTO'">S/ {{ data.item.discount_number }}</span>
                    <span v-else>{{ data.item.discount_number }} %</span>
                </template>

                <!-- Column: Expiration Date -->
                <template #cell(expiration_date)="data">
                    {{ data.item.expiration_date ? formatDate(data.item.expiration_date) : '---' }}
                </template>

                <!-- Column: Coupon Type -->
                <template #cell(coupon_type_name)="data">
                    <b-badge
                        pill
                        :variant="`light-${data.item.coupon_type.color}`"
                    >
                        {{ data.item.coupon_type.name }}
                    </b-badge>
                </template>

                <template #cell(actions)="data">
                    <b-dropdown
                        variant="link"
                        no-caret
                        :right="$store.state.appConfig.isRTL">

                        <template #button-content>
                            <feather-icon
                                icon="MoreVerticalIcon"
                                size="16"
                                class="align-middle text-body"
                            />
                        </template>

                        <b-dropdown-item :to="{ name: 'coupons-edit', params: { id: data.item.id } }">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Editar</span>
                        </b-dropdown-item>

                        <b-dropdown-item @click="deleteCoupon(data.item.id)">
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">Eliminar</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </template>

            </b-table>
            <div class="mx-2 mb-2">
                <b-row>

                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Mostrando {{ dataMeta.from }} al {{ dataMeta.to }} de {{ dataMeta.of }} registros</span>
                    </b-col>

                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end">

                        <b-pagination
                            v-model="currentPage"
                            :total-rows="totalCoupons"
                            :per-page="perPage"
                            first-number
                            last-number
                            class="mb-0 mt-1 mt-sm-0"
                            prev-class="prev-item"
                            next-class="next-item">

                            <template #prev-text>
                                <feather-icon
                                    icon="ChevronLeftIcon"
                                    size="18"
                                />
                            </template>
                            <template #next-text>
                                <feather-icon
                                    icon="ChevronRightIcon"
                                    size="18"
                                />
                            </template>
                        </b-pagination>

                    </b-col>

                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>

    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import CouponsListFilters from './CouponsListFilters.vue';
    import { useToast } from 'vue-toastification/composition';
    import couponStoreModule from '../couponStoreModule';
    import { onUnmounted } from '@vue/composition-api';
    import { avatarText } from '@core/utils/filter';
    import useCouponsList from './useCouponsList';
    import VueSweetalert2 from 'vue-sweetalert2';
    import vSelect from 'vue-select';
    import store from '@/store';
    import moment from 'moment';
    import axios from '@axios';
    import Vue from 'vue';
    import 'animate.css';

    Vue.use(VueSweetalert2);

    export default {
        components: {
            CouponsListFilters,
            vSelect
        },
        data () {
            return {
                typesOptions: []
            }
        },
        beforeMount (){
            this.getCouponTypes();
        },
        methods: {
            async getCouponTypes (){
                let response = await axios.get('/coupon-types/select');
                let data = [];
                response.data.map(item => {
                    data.push({ label: item.name, value: item.id });
                });
                this.typesOptions = data;
            },
            formatDate (date){
                return moment(date).format('DD/MM/YYYY');
            }
        },
        setup() {

            // Use toast
            const toast = useToast();

            const COUPON_APP_STORE_MODULE_NAME = 'app-coupon';

            // Register module
            if (!store.hasModule(COUPON_APP_STORE_MODULE_NAME)) store.registerModule(COUPON_APP_STORE_MODULE_NAME, couponStoreModule);

            // UnRegister on leave
            onUnmounted(() => {
                if (store.hasModule(COUPON_APP_STORE_MODULE_NAME)) store.unregisterModule(COUPON_APP_STORE_MODULE_NAME);
            });

            const {
                fetchCoupons,
                tableColumns,
                perPage,
                currentPage,
                totalCoupons,
                dataMeta,
                perPageOptions,
                searchQuery,
                sortBy,
                isSortDirDesc,
                refCouponListTable,
                refetchData,

                // Extra Filters
                rangePickerFilter,
                typesFilter
            } = useCouponsList();

            const deleteCoupon = (id) => {
                Vue.swal({
                    title: '¿Estás seguro de eliminar el cupón de descuento?',
                    text: "¡Si no lo está, puede cancelar la acción!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Sí, eliminar!',
                    cancelButtonText: 'Cancelar',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1'
                    },
                    showClass: {
                        popup: 'animate__animated animate__tada'
                    },
                    buttonsStyling: false
                }).then(result => {
                    if (result.value) {
                        store.dispatch('app-coupon/deleteCoupon', { id })
                            .then( (response) => {
                                refetchData();
                                toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: response.data.message,
                                        icon: 'CheckIcon',
                                        variant: 'success'
                                    }
                                });
                            })
                            .catch( () => {
                                toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: 'Error al eliminar el cupón de descuento',
                                        icon: 'AlertTriangleIcon',
                                        variant: 'danger'
                                    }
                                });
                            });
                    }
                });
            };

            return {

                // Sidebar

                fetchCoupons,
                tableColumns,
                perPage,
                currentPage,
                totalCoupons,
                dataMeta,
                perPageOptions,
                searchQuery,
                sortBy,
                isSortDirDesc,
                refCouponListTable,
                refetchData,

                // Extra Filters
                rangePickerFilter,
                typesFilter,

                // Filter
                avatarText,
                deleteCoupon
            }
        }
    }

</script>

<style lang="scss" scoped>

    .media {
        align-items: center;
    }

    .per-page-selector {
        width: 90px;
    }

</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
    @import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>